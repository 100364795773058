/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Logo from '../../components/logo';

import './index.less';

import IMG_BLOCK_0 from './images/美颜-h5 1备份 0.jpg';
import IMG_BLOCK_1 from './images/美颜-h5 1备份 1.jpg';
import IMG_BLOCK_2 from './images/美颜-h5 1备份 2.jpg';
import IMG_BLOCK_3 from './images/美颜-h5 1备份 3.jpg';
import IMG_BLOCK_4 from './images/美颜-h5 1备份 4.jpg';
import IMG_BLOCK_5 from './images/美颜-h5 1备份 5.jpg';
import IMG_BLOCK_6 from './images/美颜-h5 1备份 6.jpg';
import IMG_BLOCK_7 from './images/美颜-h5 1备份 7.jpg';
import IMG_POP_TIP from './images/弹窗.png';

const PageAbout = props => {
	const androidLink = '';
	const iosLink = 'https://apps.apple.com/cn/app/%E6%9F%A0%E6%AA%AC%E7%BE%8E%E9%A2%9C-%E9%9D%A2%E9%83%A8%E7%91%9C%E4%BC%BD-%E5%B1%85%E5%AE%B6%E6%8A%A4%E8%82%A4%E7%BE%8E%E5%AE%B9/id1581303613';
	const [ showTip, setShowTip ] = useState(false);

	Utils.setTitle('超燃塑形·体态管理中心');

	return (
		<section className="home-mobile-container" style={{ minHeight: window.innerHeight }}>
			{
				[ IMG_BLOCK_0, IMG_BLOCK_1, IMG_BLOCK_2, IMG_BLOCK_3, IMG_BLOCK_4, IMG_BLOCK_5, IMG_BLOCK_6, IMG_BLOCK_7 ].map((item, idx) => {
					return (
						<div key={idx} className="img-wrap">
							{
								idx == 1 && <div className="btn-wrap">
									<div className="btn-item" onClick={() => {
										if(Utils.getUA().isAndroid) return;
										if(Utils.getUA().isInWechat && Utils.getUA().isIOS) {
											setShowTip(true);
										}else{
											window.location.href = iosLink;
										}
									}} />
									<div className="btn-item" onClick={() => {
										if(Utils.getUA().isIOS) return;
										// window.location.href = androidLink;
									}} />
								</div>
							}
							<img src={item} />
						</div>
					)
				})
			}

			{
				showTip && <div className="wxpoptip" onClick={() => {
					setShowTip(false);
				}}>
					<img src={IMG_POP_TIP} />
				</div>
			}
		</section>
	);
};

export default PageAbout;

