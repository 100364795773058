/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Logo from '../../components/logo';

import './index.less';

import IMG_BG from './images/bg.jpg';

const PageAbout = props => {
	const [ config, setConfig ] = useState({});
	const getConfig = async () => {
		const result = await Request.get({
			url: '/app/config'
		});
		if(!result) return;

		setConfig(result.data);

		Utils.setTitle('服务协议&隐私政策');
	};
	useEffect(() => {
		getConfig();
	}, [ 123 ]);

	return (
		<section className="private-container">
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{
					config.serverPrivate && config.serverPrivate.length > 0 && config.serverPrivate.map((item, idx) => {
						if(item.type == 'title') {
							return (
								<span style={{ color: '#333333', fontSize: 16, paddingTop: idx == 0 ? 0 : 18, paddingBottom: 18 }} key={`title${idx}`}>{item.text}</span>
							)
						}else if(item.type == 'content') {
							const texts = item.text && item.text.split('\n') || [];
							return texts.map((titem, tidx) => {
								titem = titem.replace(/^\s+|\s+$/ig, '');
								if(!!titem) {
									return (
										<span style={{ color: '#333333', fontSize: 14, lineHeight: '28px', marginBottom: 8 }} key={`content${idx}${tidx}`}>{titem}</span>
									)
								}
							})
						}
					})
				}
			</div>
		</section>
	);
};

export default PageAbout;
