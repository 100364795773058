/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import Logo from '../../components/logo';

import './index.less';

import IMG_BLOCK_1_1 from './images/block-1-1.png';
import IMG_BLOCK_1_2 from './images/block-1-2.png';
import IMG_BLOCK_2_1 from './images/block-2-1.png';
import IMG_BLOCK_3_1 from './images/block-3-1.png';

const TABS = [
	{ label: '首页', id: 'h' },
	{ label: '什么是面部瑜伽', id: 's' },
	{ label: '多久练习一次？', id: 'd' },
	{ label: '加入我们', id: 'j' },
];
const getById = id => {
	const result = TABS.filter(t => t.id == id);
	return result[0];
}

const PageAbout = props => {
	const [ tab, setTab ] = useState(TABS[0]);

	const scrollToAnchor = (anchorName) => {
		if (anchorName) {
			let anchorElement = document.getElementById(anchorName);
			if(anchorElement) { anchorElement.scrollIntoView(); }
		}
	};

	document.addEventListener('scroll', function(e) {
		const lastKnownScrollPosition = window.scrollY + 60;
		const hotop = document.querySelector('#h').offsetTop;
		const sotop = document.querySelector('#s').offsetTop;
		const dotop = document.querySelector('#d').offsetTop;
		const jotop = document.querySelector('#j').offsetTop;

		if(lastKnownScrollPosition >= jotop) {
			setTab(getById('j'));
		}else if(lastKnownScrollPosition >= dotop){
			setTab(getById('d'));
		}else if(lastKnownScrollPosition >= sotop){
			setTab(getById('s'));
		}else if(lastKnownScrollPosition >= hotop){
			setTab(getById('h'));
		}
	});

	return (
		<section className="home-container" style={{ minHeight: window.innerHeight }}>
			<div className="navs" style={{ width: window.innerWidth }}>
				{
					TABS.map((item, idx) => {
						return (
							<div className={`item${ item.id == tab.id ? ' selected' : ''}`} key={idx} onClick={() => {
								setTab(item);
								scrollToAnchor(item.id);
							}}>{item.label}</div>
						)
					})
				}
			</div>
			<div className="contents body-container">

				<div className="content-block-wrap" id="h">
					<div className="content-block">
						<div className="content-brand"><img src={IMG_BLOCK_1_1} /></div>
						<div className="content-title"><span>面部瑜伽，</span><span>居家护肤美容。</span></div>
						<div className="content-desc">LEMON FACE YOGA</div>
						<div className="content-logo"><img src={IMG_BLOCK_1_2} /></div>
						<div className="content-intro">
							<span>@柠檬美颜</span>
							<span>专注于脸部衰老与塑形课程，是一款个性化的美化脸部锻炼应用程序，旨在通过提供独家定制的脸部瑜伽课程为您提供完美的容貌。每天仅需5分钟，通过自然健康的系统性课程，帮助你摆脱大眼袋、双下巴和皱纹的困扰，重新召唤完美童颜！让我们通过面部瑜伽进行自然清新的面部护理吧！</span>
						</div>
					</div>
				</div>

				<div className="content-block-wrap" id="s">
					<div className="content-block">
						<div className="content-title"><span>面部瑜伽，</span><span>是什么？</span></div>
						<div className="content-desc">LEMON FACE YOGA</div>
						<div className="content-intro2">
							<div className="left-content">
								<span className="bottom-space">面部瑜伽是一系列的面部练习动作，旨在调理面部肌肉。这是一种非注射的方法，可以维持美颜，减少衰老痕迹。</span>
								<span className="bottom-space">您的面部有42块独立的面部肌肉，它们都需要保持良好状态。面部瑜伽可以锻炼这些肌肉，激活它们，让你的面部结构更加紧实。这样，你的面部就能保持紧实健康，清新自然，更显年轻了。</span>
								<span>面部瑜伽有以下好处：</span>
								<span>1、面部瑜伽能加速循环血液流动，打造更有光泽、更加健康的肌肤。</span>
								<span>2、面部瑜伽可以解压，最大化减少压力纹的出现。</span>
								<span>3、定期锻炼能让面部肌肉更加柔韧和强壮，从而提升下垂的面颊，避免出现双下巴。</span>
							</div>
							<div className="right-content"><img src={IMG_BLOCK_2_1} /></div>
						</div>
					</div>
				</div>

				<div className="content-block-wrap" id="d">
					<div className="content-block">
						<div className="content-title"><span>练习多长时间</span><span>比较好？</span></div>
						<div className="content-desc">LEMON FACE YOGA</div>
						<div className="content-intro2">
							<div className="left-content">
								<span className="bottom-space">定期锻炼才能看到成效，一定要坚持练习哦！</span>
								<span className="bottom-space">如果你想更快的见到成效，就应该每天坚持练习10到20分钟。效果取决于您练习的频率，它会因人而异。大多数用户从2分钟开始，随着时间的推移增加到每天20分钟。这样练习下来，即使在前两周内，你也会看到改善的效果。但是要想获得更加持久的效果，可能需要6个月以上的练习。</span>
								<span>面部瑜伽安全吗？</span>
								<span>对于大多数人而言，面部瑜伽是安全的、温和且有效的；然而，如果你有任何皮肤问题或健康问题，请在开始前与你的医生进行核实。</span>
							</div>
							<div className="right-content"><img src={IMG_BLOCK_3_1} /></div>
						</div>
					</div>
				</div>

				<div className="content-block-wrap" id="j">
					<div className="content-block">
						<div className="content-title"><span>欢迎</span><span>加入我们！</span></div>
						<div className="content-desc">LEMON FACE YOGA</div>
						<div className="content-intro2">
							<div className="left-content">
								<span className="bottom-space poi-title">UI设计师</span>
								<span>岗位职责</span>
								<span>1. 参与或负责线上产品客户端日常迭待的UI、UX需求；</span>
								<span>2. 参与或负责移动端banner、专题活动H5、开屏等设计；</span>
								<span className="bottom-space">3. 参与或负责公司新产品的前期构思、调研、团队讨论。</span>

								<span>职位要求</span>
								<span>1. 熟练使用Sketch、Photoshop等设计相关软件，并有较强的设计工具学习能力；</span>
								<span>2. 有基础的交互设计能力；</span>
								<span>3. 有基础的动效设计能力；</span>
								<span>4. 良好的自驱力，能够主动寻找问题，改进工作；</span>
								<span>5. 视野开阔，对设计风格保持开放和学习的心态；</span>
								<span>6. 良好的协作能力，乐于沟通与分享所见所闻所感。</span>
							</div>
							<div className="left-content">
								<span className="bottom-space poi-title">推广主管</span>

								<span>岗位职责</span>
								<span>1. 核心用户的挖掘和互动， 维系社区活跃度；</span>
								<span>2. 对内容进行筛选分层，使优质内容得到充分曝光，把合适的内容推荐感兴趣的用户；</span>
								<span>3. 能够完成用户群0-1的建立和管理；</span>
								<span className="bottom-space">4. 策划系列的线上、线下活动，促进优质内容的分享和沉淀。</span>

								<span>职位要求</span>
								<span>1. 本科以上学历；</span>
								<span>2. 一年以上用户运营经验；</span>
								<span>3. 擅于挖掘、分析用户习惯，善于从用户角度思考和体验产品，懂得抓取用户心理；</span>
								<span>4. 乐于与他人沟通，有网感。</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="home-footer body-container">
				<span className="clickable" onClick={() => {
					props.history.push('/serve-private');
				}}>用户协议&隐私协议</span>
				<span>Copyright © 2021 柠檬美颜｜邮箱: 1309313599@qq.com</span>
				<span className="clickable" onClick={() => {
					window.open('https://beian.miit.gov.cn/');
				}}>沪ICP备2021009404号-1</span>
			</div>
		</section>
	);
};

export default PageAbout;

