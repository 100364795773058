/**
 * Created by jimmy on 2019/11/8.
 */

export const env = window.location.host == 'fyoga.lemonup.cn' ? 'product' : 'dev';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';
export const uploadPrefix = '/api/v1';
export const imgPrefix = env == 'product' ? '/' : 'http://127.0.0.1:33999/';

export const adminTypes = { 1: '超级管理员', 2: '普通管理员', 3: '游客'/* 游客只能浏览大盘数据 */ };