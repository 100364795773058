import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageHome from './pages/home';
import PageMobile from './pages/mobile';
import PagePrivate from './pages/private';

const App = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageHome} />
			<Route exact path="/serve-private" component={PagePrivate} />
		</div>
	</Router>
);

const AppMobile = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageMobile} />
			<Route exact path="/serve-private" component={PagePrivate} />
		</div>
	</Router>
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const clientWidth = document.documentElement.clientWidth;
const mobileGap = 960;
window.isMobile = clientWidth > mobileGap ? false : true;

const AppRender = () => (
	<React.StrictMode>
		<Suspense fallback={<div style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>}>
			{ window.isMobile && <AppMobile /> || <App /> }
		</Suspense>
	</React.StrictMode>
);

setTimeout(() => {
	ReactDOM.render(
		<AppRender />,
		document.getElementById('root')
	);
	window.addEventListener('resize', () => {
		ReactDOM.render(
			<AppRender />,
			document.getElementById('root')
		);
	});
}, 222);
