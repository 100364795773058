/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import './index.less';

const CompModel = props => {
	return (
		<div className="logo" style={props.style} />
	);
};

export default CompModel;